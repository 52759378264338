import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import Analytics from '../components/Analytics';
import BlueCircle from '../components/BlueCircle';
import Intro from '../components/Intro';
import Layout from "../components/Layout";
import LeftGolden from '../assets/svg/left-golden.svg';
import { Link } from 'gatsby';
import PinkCircle from '../components/PinkCircle';
import { QRCode } from "react-qr-svg";
import { ROUTES } from './index';
import React from "react";
import SEO from "../components/Meta";
import Video from '../components/Video';

function Steps() {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({id: "title_steps", defaultMessage: "Hoe doe je mee?"})}
        description={intl.formatMessage({
          id: "description_steps",
          defaultMessage: "Klaar om mee te zingen? Volg deze stappen."})}
        keywords={[`dww`, `de warmste week`, `het warmste koor`, `hoe`, `zingen`]}
      />

      <Analytics
        title="Deelnemen"
      />

      <Intro title={intl.formatMessage({id: "steps_title", defaultMessage: "Volg deze stappen"})}>
        <FormattedMessage
            id="steps_description"
            defaultMessage="Klaar om mee te zingen? Volg deze stappen." />
      </Intro>

      <section className="container pr-4 my-section">
          <div className="flex max-w-xl -ml-16">
            <BlueCircle showBadge={true}/>

            <div className="sm:pl-8">
              <h2 className="h1">
                <FormattedMessage
                  id="step_1_title"
                  defaultMessage="Camera loopt..." />
              </h2>

              <p>
                <FormattedMessage
                  id="step_1_description"
                  defaultMessage="Laat de camera van je GSM of tablet alvast lopen en zet je schrap voor het optreden van je leven." />
              </p>
            </div>
          </div>
      </section>

      <section className="relative py-section">
        <div className="container">
          <div className="max-w-xl ml-auto lg:mr-20 xl:mr-48">
            <div className="flex mb-4 -ml-16 sm:mb-8 sm:ml-auto">
              <PinkCircle showBadge={true}/>

              <div className="sm:pl-8">
                <h2 className="h1">
                  <FormattedMessage
                    id="step_2_title"
                    defaultMessage="en... actie!" />
                </h2>

                <p>
                  <FormattedMessage
                    id="step_2_description"
                    defaultMessage="Start op het andere toestel de karaoke-video en zing de sterren van de hemel." />
                </p>
              </div>
            </div>

            <Video url="https://youtu.be/FfIz0NMekik" thumb="karaoke.jpg" />
          </div>
        </div>

        <LeftGolden className="absolute bottom-0 left-0 w-24 h-auto -ml-20 sm:-ml-12 sm:w-32 lg:-ml-4 xl:ml-0 lg:mb-32" aria-hidden="true" />
      </section>

      <section className="container pr-4 py-section">
          <div className="flex max-w-xl -ml-16">
            <BlueCircle showBadge={true} number="3"/>

            <div className="sm:pl-8">
              <h2 className="h1">
                <FormattedMessage
                  id="step_3_title"
                  defaultMessage="Deel je filmpje met ons" />
              </h2>

              <Link to={ROUTES.UPLOAD} className="no-underline">
                <FormattedMessage
                  id="step_3_description"
                  defaultMessage="En...cut! Tevreden met je optreden? Bewaar de opname en surf met je toestel naar deze link:" />
              </Link>

              <a className="block mt-4 mb-12 font-bold text-md sm:text-lg" href="https://rebrand.ly/hwk">
                https://rebrand.ly/hwk
              </a>

              <p>
                <FormattedMessage
                  id="step_3_scan"
                  defaultMessage="Of scan deze QR-code:" />
              </p>

              <QRCode
                  level="Q"
                  className="w-32 my-8"
                  value="https://hetwarmstekoor.radio2.be/uploaden"
              />
            </div>
          </div>
      </section>
    </Layout>
  );
}

export default Steps;
